import React, {Component} from 'react';
import {graphql, StaticQuery} from "gatsby";
import styled from "styled-components";
import {Container} from "reactstrap";
import Layout from "components/Layout/Layout"
import checkboxActive from 'images/icons/checkbox-active.svg'
import checkboxInactive from 'images/icons/checkbox-inactive.svg'
import ResourceListNew from 'components/ResourceList/ResourceListNew'
import HeaderCopy from "components/shared/HeaderCopy"
import {media} from "utils/Media"
import iconFilter from "images/icons/icon-filter.svg"
import IconClose from "images/icons/icon-close.svg"

const Flex = styled.div`
    display: flex;
    flex-flow: row wrap;
    align-content: space-between;
    justify-content: space-between;
`

const CategoryPanel = styled.div`
    width: 220px;
    
    .title {
        font-size: 1.125rem;
        margin-bottom: 0.5rem;   
    }
    
    .sticky {
        border-right: 1px solid ${props => props.theme.colors.lightGrey};
        border-bottom: 1px solid ${props => props.theme.colors.lightGrey};
        padding-bottom: 1rem !important;
    }
    
    .sticky.active {
        position: fixed;
        width: 100%;
        z-index: 501;
        bottom: 0;
        height: 58px;
        overflow: hidden;
        background: white;
        left: 0;
        padding: 1rem;
        box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1);
        
        &.open {
            height: 100%;
        }
        
        @media ${media.md} {
            box-shadow: none;   
            padding: 0;
            left: initial;
            width: 220px;
            height: initial;
            overflow: auto;
            bottom: initial;    
            z-index: 1;
            top: 65px;
            max-height: calc(100% - 175px);
        }
    }
`

const Filter = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 2rem;
    cursor: pointer;
    
    @media ${media.md} {
        cursor: normal;
    }
    
    &.open {
        flex-flow: row wrap;
        align-content: space-between;
        justify-content: space-between;
    }
    
    p {
        padding-left: 1rem;
        margin-bottom: 0 !important;
        
        @media ${media.md} {
            padding-left: 0;
        }
    }
`

const Content = styled.div`
    height: 100%;
    width: 100%;
    
    @media ${media.md} {
        width: calc(100% - 240px);
    }
`

const CategoryList = styled.ul`
    list-style: none;
    margin: 0;
    padding: 0;
    
    strong {
        font-family: "Proxima Nova W05 Light", Arial, sans-serif;
    }
     
    li {
        font-size: 1rem;
        margin-bottom: 0.5rem;
        cursor: pointer;
    }
`

const ClearSelection = styled.button`
    font-family: ${props => props.theme.font.family.light};
    text-decoration: underline;
    font-size: 0.8125rem;
    background: transparent;
    border: 0;
    text-align: left;
    padding: 8px 0;
    width: 100%;
`



const Query = () => (
    <StaticQuery
        query={graphql`
            query {
                allCategoriesJson {
                    edges {
                        node {
                            parentDatabaseId
                            databaseId
                            slug
                            name
                        }
                    }
                }
              allTeacherLibraryJson {
                edges {
                  node {
                    id
                    tag
                    slug
                    title
                    body
                    selector
                    link
                    categories
                  }
                }
              }
             
            }

		`}
        render={data => (
            <ResourcePage data={data}/>
        )}
    />
)

class ResourcePage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedCategories: [],
            sticky: false,
            filterOpen: false
        }
    }

    componentDidMount() {
        const width = window.innerWidth;

        if (width < 768) {
            this.setState({
                sticky: true
            })
        }

        window.addEventListener('scroll', this.handleScroll, true)
        window.addEventListener('resize', this.handleScroll, true)
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll, true)
        window.removeEventListener('resize', this.handleScroll, true)
    }

    handleScroll = () => {
        let scrollPosition = Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop)

        const width = window.innerWidth;

        if (width > 767) {
            let sticky = scrollPosition < 156 ? false : true

            this.setState({
                sticky
            })
        } else {
            this.setState({
                sticky: true
            })
        }
    }

    selectCategory = (category, e) => {

        if (e.key === " " || e.keyCode === undefined) {
            console.log(category, this.state.selectedCategories)
        
            let selectedCategories = this.state.selectedCategories

            if (selectedCategories.includes(category)) {
                const index = selectedCategories.indexOf(category);
                selectedCategories.splice(index, 1);
            } else {
                selectedCategories.push(category)
            }

            this.setState({selectedCategories})

        }
    }

    toggleFilter = () => {
        const filterOpen = !this.state.filterOpen;

        this.setState({
            filterOpen
        })
    }

    clearCategories = () => {
        this.setState({
            selectedCategories: []
        })
    }

    render() {
        const categories = this.props.data.allCategoriesJson.edges.map((cat, id) => {
            if (cat.node.slug !== 'uncategorized') {
                let databaseId = parseInt(cat.node.databaseId)
                if (cat.node.parentDatabaseId === "0") {
                    return (
                        <li key={id} role="label" className={`${cat.node.slug} mt-4`}><strong dangerouslySetInnerHTML={{__html: cat.node.name }} /></li>
                    )
                } else {
                    return (
                        <li key={id} role="checkbox" aria-checked={this.state.selectedCategories.includes(databaseId)} tabIndex="0" className={`${cat.node.slug}`} onKeyPress={(e) => this.selectCategory(databaseId, e)} onClick={(e) => this.selectCategory(databaseId, e)}>
                            <span dangerouslySetInnerHTML={{__html: cat.node.name }} />
                            {this.state.selectedCategories.includes(databaseId) ?
                                <img style={{float: 'right', paddingRight: '1rem'}} src={checkboxActive} alt=""/>
                            :
                                <img style={{float: 'right', paddingRight: '1rem'}} src={checkboxInactive} alt=""/>
                            }

                        </li>
                    )
                }
            }
        })

        return (
            <Layout slug="teacher-library" title="Teacher Resource Library">
                <HeaderCopy title="Teacher Resource Library"/>
                <Container fluid={true} className="my-4">
                    <Container>
                        <Flex>
                            <CategoryPanel aria-controls="Filter">
                                <div className={`sticky ${this.state.sticky && 'active'} ${this.state.filterOpen && 'open'}`}>
                                    <Filter className={this.state.filterOpen && 'open'}>
                                        {this.state.filterOpen ?
                                            <>
                                                <img onClick={this.toggleFilter} src={IconClose} alt="" className="d-md-none"/>
                                                <ClearSelection role="button" tabIndex="0" onClick={this.clearCategories}>Clear Selection</ClearSelection>
                                            </>
                                        :
                                            <>
                                                <img onClick={this.toggleFilter} src={iconFilter} alt="" className="d-md-none"/>
                                                <p className="title" onClick={this.toggleFilter} >Filter</p>
                                            </>
                                        }
                                    </Filter>
                                    <CategoryList>
                                        {categories}
                                        <li tabIndex="0">
                                            <ClearSelection role="button" tabIndex="0" onClick={this.clearCategories}>Clear Selection</ClearSelection>
                                        </li>
                                    </CategoryList>
                                </div>
                            </CategoryPanel>
                            <Content>
                                <ResourceListNew resources={this.props.data.allTeacherLibraryJson.edges} categories={this.state.selectedCategories}/>
                            </Content>
                        </Flex>
                    </Container>
                </Container>
            </Layout>
        );
    }
}

export default Query;