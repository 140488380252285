import React, {Component} from 'react';
import styled from "styled-components";
import CardItemNew from 'components/Cards/CardItemNew'

const Resources = styled.div`
  margin-bottom: 1rem;

  .card-deck {
    display: flex;
    flex-flow: row wrap;
    justify-content: left;
  }

  h2 {
    padding-left: 0.5rem;
  }
`

class ResourceListNew extends Component {
    listResources = (tag, color) => {
        let totalTags = 0;
        let count = 0;
        const getResources = this.props.resources.map((resource) => {
            let catArray = []
            resource.node.categories.map((cat, k) => {
                catArray.push(cat)
            })

            let currentTag = resource.node.tag

            let checkIfCategory = (arr, target) => target.every(v => arr.includes(v));
            // console.log(catArray, this.props.categories)

            if (currentTag === tag) {
                count ++;
                if (checkIfCategory(catArray, this.props.categories)) {
                    totalTags++
                    return (
                        <CardItemNew key={resource.node.id} color={color} card={resource.node}/>
                    )
                } else {
                    return false
                }
            }
        })
        if (totalTags > 0) {
            return (
                <Resources>
                    {tag === 'primary' &&
                        <h2><strong>Primary School Lesson Plans</strong> with Accompanying Activity Sheets</h2>
                    }
                    {tag === 'secondary' &&
                        <h2><strong>Secondary School Lesson Plans</strong> with Accompanying Activity Sheets</h2>
                    }
                    {tag === 'behind' &&
                        <h2><strong>Behind-The-Scenes Videos</strong></h2>
                    }
                    {tag === 'backstage' &&
                        <h2><strong>Backstage Careers Documentary</strong> - Getting Ready to Roar</h2>
                    }
                    {tag === 'citizenship' &&
                        <h2><strong>Community and Citizenship Projects</strong></h2>
                    }
                    {tag === 'background' &&
                        <h2><strong>Background Reading</strong></h2>
                    }
                    {tag === 'video-questionnaires' &&
                        <h2><strong>Video Hub Questionnaires</strong></h2>
                    }
                    <div className="card-deck-wrapper">
                        <div className="card-deck">
                            {getResources}
                        </div>
                    </div>
                </Resources>
            )
        }
    }

    render() {
        return (
            <div>
                {this.listResources('primary', 'orange')}
                {this.listResources('secondary', 'blue')}
                {this.listResources('behind', 'yellow')}
                {this.listResources('backstage', 'yellow')}
                {this.listResources('citizenship', 'purple')}
                {this.listResources('background', 'green')}
                {this.listResources('video-questionnaires', 'yellow')}
            </div>
        );
    }
}

export default ResourceListNew;